import { graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import HTMLRenderer from 'react-html-renderer'
import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import { Box, Flex, FlexItem } from "../elements"
import website from "../../config/website"

const TextWrapper = styled.div`
  ${props => props.theme.devices.desktop} {
    margin-top: 20px;
  }
`

const Page = ({ data: { prismicPage: page }, location, pageContext: { breadcrumb } }) => {  
  const desc = page.data.meta_description || page.data.title.text
  const title = page.data.meta_title || page.data.title.text

  return (
    <Layout breadcrumb={breadcrumb} location={location}>
      <SEO
        title={`${title} | ${website.titleAlt}`}
        pathname={location.pathname}
        desc={desc}
        node={page}
      />
      <Box ml={["12px", "12px", 0, 0]}>
        <h1>{page.data.title.text}</h1>
      </Box>

      {page.data.image_banner && (
        <Img
          objectFit="fill"
          fluid={page.data.image_banner.fluid}
          alt={page.data.image_banner.alt}
        />
      )}
      {page.data.more_content ? (
        <Flex flexWrap={["wrap", "wrap", "nowrap", "nowrap"]}>
          <FlexItem flex={["100%", "100%", "50%", "50%"]}  pr={[0, 0, "10px", "10px"]}>
            <HTMLRenderer html={page.data.content.html}/>             
          </FlexItem>
          <FlexItem flex={["100%", "100%", "50%", "50%"]}>
            <HTMLRenderer html={page.data.more_content.html}/>
          </FlexItem>
        </Flex>
      ) : (
        <Box m={["0 12px", "0 12px", 0, 0]}>
          <TextWrapper><HTMLRenderer html={page.data.content.html}/></TextWrapper>
        </Box>
      )}
    </Layout>
  )
}

export default Page

Page.propTypes = {
  data: PropTypes.shape({
    prismicPage: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    prismicPage(uid: {eq: $uid}, lang: {eq: "en-us"}) {      
      uid
      data {
        title {
          text
        }
        image_banner{
          url
          alt
          fluid(maxWidth: 2000) {
            ...GatsbyPrismicImageFluid
          }
        }
        meta_description        
        content {
          text
          html
        }
        more_content {
          text
          html
        }
        meta_title
      }
    }    
  }
`
